import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { breakpoints, layout, borders } from '../../theme'
import {
  Button,
  NumberInput,
  StyledNumberInput,
  Dropdown,
  StyledDropdown,
  Pulse,
} from '@monbanquet/crumble'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { EventContext } from '../../components/context/EventContext'
import { navigate } from '../../components/Link'
import { useRedirect } from '../../hooks/useRedirect'
import { useNoValidate } from '../../hooks/useNoValidate'
import * as yup from 'yup'
import { useFormalWithProps } from '../../hooks/useFormalWithProps'
import { useWithDelay } from '../../hooks/useWithDelay'
import { defaultNbGuestsTypes } from '../../components/context/EventContextDefaults'
import DemandeLayout from '../../components/DemandeLayout'
import { computeNextPageWithBaseUri } from '../../util/compute-next-page'

const schema = yup.object().shape({
  nbGuestsForm: yup
    .number()
    .nullable()
    .min(15, '15 convives minimum')
    .required('15 convives minimum'),
})

const GuestPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')

  const {
    state: { nbGuestsForm, nbGuestsType, multiMoment },
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const noValidate = useNoValidate()

  const initialValues = {
    nbGuestsForm,
  }

  const submit = () => {
    navigate(computeNextPageWithBaseUri('demande', 'convives'), {
      state: { next: true },
    })
  }
  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  const { formal, nbGuestsFormProps } = useFormalWithProps(initialValues, {
    schema,
    onSubmit: submitWithDelay,
  })

  // preselect nbGuestsType
  useEffect(() => {
    if (!nbGuestsType) {
      dispatchEventCtx({
        type: 'UPDATE',
        payload: {
          nbGuestsType: multiMoment
            ? defaultNbGuestsTypes[0].value // average by banquet
            : defaultNbGuestsTypes[1].value, // total
        },
      })
    }
  }, [])

  return (
    <StyledGuestPage multiMoment={multiMoment}>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle
              title={`Combien de personnes sont attendues\u00A0?`}
            />

            <form
              noValidate={noValidate}
              onSubmit={async e => {
                e.preventDefault()
                try {
                  await formal.validate()
                  await formal.submit()
                } catch (err) {
                  console.info(err)
                }
              }}
            >
              <div className="dropdown-input">
                <NumberInput
                  id="nbGuests"
                  label="Nombre de convives"
                  autoComplete="off"
                  autoFocus
                  secondary
                  min="0"
                  required
                  placeholder="Nombre de convives (15 minimum)"
                  {...nbGuestsFormProps}
                  onChange={nbGf => {
                    formal.change('nbGuestsForm', nbGf)
                    dispatchEventCtx({
                      type: 'UPDATE',
                      payload: { nbGuestsForm: nbGf },
                    })
                  }}
                />
                {multiMoment && nbGuestsType && (
                  <Dropdown
                    secondary
                    items={defaultNbGuestsTypes}
                    value={nbGuestsType}
                    onChange={nbGt =>
                      dispatchEventCtx({
                        type: 'UPDATE',
                        payload: { nbGuestsType: nbGt },
                      })
                    }
                  />
                )}
              </div>

              <div className="btn-bar">
                <Button
                  id="previous"
                  className="previous"
                  onClick={() => navigate(-1)}
                >
                  <div>&rarr;</div>
                  <span>&nbsp;Précédent</span>
                </Button>
                <Button id="next" type="submit">
                  {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
                </Button>
              </div>
            </form>
          </div>
        )}
      </DemandeLayout>
    </StyledGuestPage>
  )
}

const StyledGuestPage = styled.div`
  .page-content {
    max-width: 500px;
  }
  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
      margin-bottom: 50px;
    }
  }

  .dropdown-input {
    display: flex;
    margin: 40px auto;

    ${StyledNumberInput} {
      flex-grow: 1;

      input {
        ${({ multiMoment }) =>
          multiMoment
            ? 'border-radius: 37px 0 0 37px; padding: 20px 25px; ::placeholder{font-size:15px}'
            : ''}
      }
      ::placeholder {
        ${({ multiMoment }) => (multiMoment ? 'font-size: 15px' : '')}
      }
    }

    ${StyledDropdown} {
      flex-basis: 40%;
      flex-shrink: 0;

      button {
        height: 100%;
        border-radius: 0 37px 37px 0;
        margin-left: -1px;
        background-color: white;
        font-size: 0.9rem;
        color: black;
        border: none;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }
`

export default GuestPage
export { StyledGuestPage }
